import { useUserStore } from "@/stores/user";
import { computed } from "vue";
import { useAccessToken } from "~/composables/useAccessToken";

export function useDelegates() {

  const {getAccessToken} = useAccessToken();
  const { getAccount, profile} = useUserStore();

  const fetchDelegates = async (params = {}) => {
    try {
      const { $api } = useNuxtApp();
      const token = await getAccessToken();

      const response = await $api.get('/delegates', {
        params: {
          account_id: getAccount.id,
          ...params
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return response.data;

    } catch (error) {
      throw error;
    }
  };

  const inviteDelegate = async (params = {}) => {
    try {
      const { $api } = useNuxtApp();
      const token = await getAccessToken();

      const response = await $api.post('/delegates/invite', {
        account_id: getAccount.id,
        ...params
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return response.data;

    } catch (error) {
      throw error;
    }
  }

  const cancelDelegateInvite = async (params = {}) => {
    try {
      const { $api } = useNuxtApp();
      const token = await getAccessToken();

      const response = await $api.post('/delegates/cancel_invite', {
        account_id: getAccount.id,
        ...params
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return response.data;

    } catch (error) {
      throw error;
    }
  }

  const rejectDelegateInvite = async (params = {}) => {
    try {
      const { $api } = useNuxtApp();
      const token = await getAccessToken();

      const response = await $api.post('/delegates/reject_invite', {
        account_id: getAccount.id,
        ...params
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return response.data;

    } catch (error) {
      throw error;
    }
  }

  const revokeDelegateAccess = async (params = {}) => {
    try {
      const { $api } = useNuxtApp();
      const token = await getAccessToken();

      const response = await $api.post('/delegates/revoke_access', {
        account_id: getAccount.id,
        ...params
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return response.data;

    } catch (error) {
      throw error;
    }
  }

  const getDelegateInviteToken = async (delegateInvitetoken: string) => {
    try {
      const { $api } = useNuxtApp();
      const token = await getAccessToken();

      const response = await $api.get('/delegates/token/'+ delegateInvitetoken, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return response.data;

    } catch (error) {
      throw error;
    }
  }

  const acceptDelegateInvite = async (delegateInvitetoken: string) => {
    try {
      const { $api } = useNuxtApp();
      const token = await getAccessToken();

      const response = await $api.post('/delegates/accept', {
        token: delegateInvitetoken
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return response.data;

    } catch (error) {
      throw error;
    }
  };

  // const requestFullDelegateAccess = async (params) => {
  //   try {
  //     const accessToken = await getAccessTokenSilently();
  //     const api = createApiInstance(accessToken);
  //
  //     const response = await api.post('/delegates/accept', {
  //       token: token
  //     });
  //
  //     return response.data;
  //
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  const accessDelegateAccount = async(delegateId: string) => {
    try {
      const { $api } = useNuxtApp();
      const token = await getAccessToken();

        const response = await $api.get('/delegates/' + delegateId, {
          params: {
            account_id: getAccount.id,
          },
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.data.account_id) {
            sessionStorage.setItem('delegate_access_account', response.data.account_id);
            return true;
        }
      } catch (error) {
        throw error;
      }
  }

  const isInDelegateMode = computed(() => {
    return profile.is_delegate_mode;
  });

  const exitDelegateMode = () => {
    sessionStorage.removeItem('delegate_access_account');
    window.location.href = '/account/dashboard';
  }

  return {
    fetchDelegates,
    getDelegateInviteToken,
    acceptDelegateInvite,
    inviteDelegate,
    isInDelegateMode,
    exitDelegateMode,
    accessDelegateAccount,
    cancelDelegateInvite,
    rejectDelegateInvite,
    revokeDelegateAccess
  };
}
